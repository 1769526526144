import { AnimatePresence } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../App.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { UserProvider } from "../context/userContext";
import { useAuthContext } from "../context/AuthContext";
import "../fire.scss";
import { getUserInfo } from "../services/auth.service";

const tele = window.Telegram.WebApp;
const Main = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [user, setUser] = useState();
  const { doLogin,doLogout } = useAuthContext();

  const fetchUser = async () => {
    try {
      const { data } = await getUserInfo();
      if (data.success === 1) {
        doLogin();
        setUser(data);
      } else if (data.success === 4) {
        localStorage.removeItem("tokenUser");
        doLogout();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    tele.ready();
    tele.expand();
    fetchUser();

    window.Telegram.WebApp.setHeaderColor("#1b1b1b"); // Set header color to red

    // Haptic feedback
    if (tele.HapticFeedback) {
      tele.HapticFeedback.impactOccurred("medium");
    }
  }, []);

  const overflow = 100;
  const scrollableEl = useRef(null);

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    // document.body.style.marginTop = `${overflow}px`;
    document.body.style.height = `${window.innerHeight + overflow}px`;
    document.body.style.paddingBottom = `${overflow}px`;
    window.scrollTo(0, overflow);

    let ts;

    const onTouchStart = (e) => {
      ts = e.touches[0].clientY;
    };

    const onTouchMove = (e) => {
      const el = scrollableEl.current;
      if (el) {
        const scroll = el.scrollTop;
        const te = e.changedTouches[0].clientY;
        if (scroll <= 0 && ts < te) {
          e.preventDefault();
        }
      } else {
        e.preventDefault();
      }
    };
    const onTouchMoveWithException = (e) => {
      const target = e.target.closest("#refer");
      if (!target) {
        onTouchMove(e);
      }
    };

    document.documentElement.addEventListener("touchstart", onTouchStart, {
      passive: false,
    });
    document.documentElement.addEventListener(
      "touchmove",
      onTouchMoveWithException,
      { passive: false }
    );

    // Cleanup event listeners on component unmount
    return () => {
      document.documentElement.removeEventListener("touchstart", onTouchStart);
      document.documentElement.removeEventListener(
        "touchmove",
        onTouchMoveWithException
      );
    };
  }, [location.pathname, overflow]);

  return (
    <>
      <div className="flex justify-center w-full">
        <div className="flex justify-center w-full">
          <div className="flex flex-col w-full space-y-3">
            <UserProvider user={user}>
              <AnimatePresence mode="wait">
                <Header />
                <div className="!mt-[76px] pb-[100px]">
                  <Outlet />
                </div>
                <div
                  id="footermain"
                  className={`${loading ? "invisible" : "visible"
                    } z-30 flex flex-col fixed bottom-0 py-2 left-0 right-0 justify-center items-center px-3`}
                >
                  <Footer />
                </div>
              </AnimatePresence>
            </UserProvider>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Main;
