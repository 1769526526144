import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import React from "react";

const Modal = ({
  visible = false,
  onOk,
  onCancel,
  className,
  okText = "OK",
  contentClassName,
  cancelText = "Cancel",
  children,
  centered,
  title,
  hideOkBtn,
  hideCancelBtn,
  hidenClose,
  loading = false,
  isEnableOk = false,
  isEnableSecond = false,
  secondText = "",
  onSecond,
  disabledAll = false,
}) => {
  const onClose = () => {
    onCancel && onCancel();
  };

  return (
    <Dialog open={visible} onClose={onClose} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-[#1D1F21] px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full md:w-[80vw] sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="pb-2 font-semibold text-white border-b">
              {" "}
              {title}
            </div>
            <div className="mt-4 sm:flex sm:items-start">{children}</div>
            <div className="flex gap-3 mt-5 sm:mt-4 ">
              <button
                type="button"
                onClick={onOk}
                disabled={!isEnableOk || disabledAll}
                className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-sky-500 hover:bg-sky-400 disabled:opacity-55 disabled:bg-sky-700 disabled:cursor-not-allowed sm:w-auto "
              >
                {okText}
              </button>
              {secondText !== "" && (
                <button
                  type="button"
                  onClick={onSecond}
                  
                  disabled={!isEnableSecond || disabledAll}
                  className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-cyan-500 hover:bg-cyan-400 disabled:opacity-55 disabled:bg-sky-700 disabled:cursor-not-allowed sm:w-auto"
                >
                  {secondText}
                </button>
              )}
              <button
                type="button"
                data-autofocus
                disabled={disabledAll}
                onClick={onClose}
                className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-black bg-white rounded-md shadow-sm ring-1 ring-inset disabled:opacity-55 ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                {cancelText}
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default Modal;
