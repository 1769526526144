import React from "react";
import Animate from "../Animate";

const InformationPage = () => {
  return (
    <Animate>
      <div className="bg-black h-screen w-full">
        <div className="flex flex-col justify-center w-full space-y-3 px-3 pt-4">
          <section>
            <header className="mb-4">
              <h1 className="text-center">Introduction to Auto-Trade Bot</h1>
            </header>
            <article>
              <p>
                An auto-trade bot is an intelligent software application
                designed to automate trading activities in financial markets. It
                operates based on pre-defined algorithms, analyzing market
                trends, price movements, and other key indicators to execute
                trades with precision and efficiency.
              </p>
              <p>
                The bot eliminates emotional decision-making, ensuring
                consistent performance and minimizing human error. Ideal for
                both beginner and experienced traders, an auto-trade bot can
                operate 24/7, identifying opportunities and optimizing profits
                even when you’re away.
              </p>
              <p>
                Whether you’re trading stocks, forex, or cryptocurrencies, an
                auto-trade bot is a powerful tool to enhance your trading
                strategy and maximize results.
              </p>
            </article>
          </section>
        </div>
      </div>
    </Animate>
  );
};

export default InformationPage;
