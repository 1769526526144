import { Fragment, useEffect, useState } from "react";

import {
  HiArrowLongLeft,
  HiArrowLongRight,
  HiChevronLeft,
  HiChevronRight,
} from "react-icons/hi2";

const Pagination = ({
  total,
  current = 1,
  pageSize,
  onChange,
  className = "",
}) => {
  const [currentPage, setCurrentPage] = useState(current);
  const [currentLimit, setCurrentLimit] = useState(pageSize.toString());
  const totalPage = Math.ceil(total / pageSize);

  const onChangePage = (page) => {
    setCurrentPage(page);
    onChange && onChange(page);
  };

  const prevPage = () => {
    currentPage !== 1 && onChangePage(currentPage - 1);
  };

  const nextPage = () => {
    currentPage !== totalPage &&
      totalPage !== 0 &&
      onChangePage(currentPage + 1);
  };

  let startPage = currentPage - 2;
  let endPage = currentPage + 2;

  if (startPage <= 0) {
    endPage -= startPage - 1;
    startPage = 1;
  }

  if (endPage > totalPage) {
    endPage = totalPage;
  }

  useEffect(() => {
    setCurrentPage(current);
    setCurrentLimit(String(pageSize));
  }, [current, pageSize]);

  return (
    <div
      className={`flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 ${
        className ? className : ""
      }`}
    >
      <div className="pg-paginate-mobile flex-1 flex justify-between sm:hidden">
        <button
          onClick={prevPage}
          className={`inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium  ${
            currentPage === 1 ? "text-gray-700 cursor-none" : "text-white"
          }`}
        >
          <HiArrowLongLeft
            aria-hidden="true"
            className={`mr-3 size-5 ${
              currentPage === 1 ? "text-gray-700 cursor-none" : "text-white"
            }`}
          />
          Previous
        </button>
        <button
          onClick={nextPage}
          className={`inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium  ${
            currentPage === totalPage
              ? "text-gray-700 cursor-none"
              : "text-white"
          }`}
        >
          Next
          <HiArrowLongRight
            className={`ml-3 size-5 ${
              currentPage === totalPage
                ? "text-gray-700 cursor-none"
                : "text-white"
            }`}
            aria-hidden="true"
          />
        </button>
      </div>
    </div>
  );
};

const Page = ({ current, page, onChangePage }) => {
  return current === page ? (
    <button
      aria-current="page"
      className={`pg-page-item pg-page-item-${page} z-10 bg-cyan-50 border-cyan-500 text-primaryColor relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
    >
      {page}
    </button>
  ) : (
    <button
      onClick={() => onChangePage(page)}
      className={`pg-page-item pg-page-item-${page} bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
    >
      {page}
    </button>
  );
};

const PageBreak = () => (
  <span className="pg-paginate-page-break relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
    ...
  </span>
);

export default Pagination;
