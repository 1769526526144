import React from "react";

//* Create FormControls component to be used in Login & Signup pages
export default function FormControls({
  label,
  type,
  id,
  value,
  placeholder,
  setValue,
}) {
  return (
    <div className="pb-5 flex flex-col items-start w-full sm:w-[48%]">
      <label
        htmlFor={id}
        className="block text-sm/6 font-medium text-white pb-1"
      >
        {label}
      </label>
      <input
        className="block w-full rounded-md border-0 bg-white/5 py-4 px-3 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm/6"
        onChange={(e) => setValue(e.target.value)}
        required
        value={value}
        type={type}
        name={id}
        id={id}
        placeholder={placeholder}
      />
    </div>
  );
}
