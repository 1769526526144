import React, { useEffect, useState } from "react";
import Animate from "../Animate";
import { getHistoryBet } from "../../services/auto.service";
import { formatDate } from "../../utils/date";
import { useUser } from "../../context/userContext";
import { formatMoney } from "../../utils";
import { SETTING_INFO } from "../../constants/settings";
import Pagination from "../ui/Pagination";

const DEFAULT_PAGESIZE = 10;

const HistoryPage = () => {
  const [history, setHistory] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const { email, isLogin } = useUser();

  const fetchHistory = async () => {
    try {
      const { data } = await getHistoryBet(page);
      setHistory(data.result);
      setTotal(data.total);
    } catch (error) {}
  };

  useEffect(() => {
    if (isLogin) fetchHistory();
  }, [isLogin, page]);

  return (
    <Animate>
      <div className="bg-black h-screen w-full">
        <div className="flex flex-col justify-center w-full space-y-3 px-3 pt-4">
          <div className="">
            <h2 className="text-center mb-4 text-gray-200">History</h2>
            <table className="min-w-full divide-y divide-gray-700">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 text-center text-sm font-semibold text-white sm:pl-0"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-center text-sm font-semibold text-white"
                  >
                    Order
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-center text-sm font-semibold text-white"
                  >
                    Percent
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-center text-sm font-semibold text-white"
                  >
                    Bot
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-800">
                {history.map((history) => (
                  <tr key={history.id}>
                    <td className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                      {formatDate(history.betted_at, {
                        dateFormat: "DD/MM/YYYY HH:mm",
                      })}
                    </td>
                    <td
                      className={`whitespace-nowrap text-center px-3 py-4 text-sm text-gray-300 ${
                        history.percent >= 0 ? "text-green-300" : "text-red-300"
                      }`}
                    >
                      {history.order}
                    </td>
                    <td
                      className={`whitespace-nowrap text-center px-3 py-4 text-sm text-gray-300 ${
                        history.percent >= 0 ? "text-green-300" : "text-red-300"
                      }`}
                    >
                      {formatMoney(history.percent)}
                    </td>
                    <td
                      className={`whitespace-nowrap text-center px-3 py-4 text-sm `}
                    >
                      {SETTING_INFO[history.bot_type].label}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              total={total}
              pageSize={DEFAULT_PAGESIZE}
              onChange={(page) => setPage(page)}
              current={page}
            />
          </div>
        </div>
      </div>
    </Animate>
  );
};

export default HistoryPage;
