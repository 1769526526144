import React from "react";
import { useUser } from "../context/userContext";
import { formatMoney } from "../utils";

const Header = () => {
  const { nickName, balance } = useUser();
  const { isLogin, setIsLogin, handleUser } = useUser();

  const handerLogoutClick = () => {
    setIsLogin(false);
    localStorage.removeItem("tokenUser");
    window.location.reload();
  }
  if (!isLogin) return <></>;
  return (
    <>
      <div id="header" className="bg-[#202938] w-full flex fixed z-30">
        <div className="flex items-center gap-4 py-2 mx-4 left-menu">
          <img
            alt=""
            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            className="inline-block rounded-full size-8"
          />
          <div className="flex flex-col text-sm text-white">
            <span>{nickName}</span>
            <span>{formatMoney(balance)}</span>
          </div>
        </div>
        <div className="flex justify-end flex-1 py-2 mx-4 nav-right">
          <button onClick={handerLogoutClick} className="inline-flex items-center justify-center px-2 py-1 text-sm rounded-lg bg-custom-buttonLight hover:bg-[#ba895d] ">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
            Logout
          </button>
        </div>
      </div>
    </>
  );
};

export default Header;
