import axios from "axios";

const API_BASE_URL =
  process.env.REACT_APP_API_URL || "http://localhost:8888/api";

const instance = axios.create({
  headers: { "Content-Type": "application/json" },
  baseURL: `${API_BASE_URL}/auto-trade`,
});

instance.interceptors.request.use(async (req) => {
  
  const token = localStorage.getItem("tokenUser");
  req.headers["Authorization"] = `Sky ${token}`;

  return req;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle errors
    const { response } = error;

    let res = {};

    if (!response) throw new Error("Something went wrong");

    res.status = response.status;
    res.statusText = response.statusText;

    if (!response.data) throw new Error("Something went wrong");

    res = { ...res, ...response.data };

    throw res;
  }
);

export default instance;
