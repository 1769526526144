export const classNames = (...classes) => classes.filter(Boolean).join(" ");

export const formatMoney = (money) => {
  return Number(money).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export const calculateProfitMargin = (winAmount, loseAmount) => {
  if (loseAmount === 0 && winAmount === 0) return 0;
  if (loseAmount <= 0) return 100;
  const profit = +winAmount - +loseAmount;
  const profitMargin = (profit / loseAmount) * 100;
  return Number(profitMargin.toFixed(2));
};

export const formatNumber = (amount, digit = 0) => {
  const formattedNumber = new Intl.NumberFormat('en-US', { minimumFractionDigits: digit, maximumFractionDigits: digit }).format(amount);
  return formattedNumber;
}

export const removeDigitNumber = (amount) => {
  // Step 1: Remove commas
  const withoutCommas = amount.replace(/,/g, "");

  // Step 2: Convert to a number and round to an integer
  const number = Math.floor(parseFloat(withoutCommas));
  return number;
}
