import React, { useState } from "react";
import Drawer from "../ui/Drawer";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { HiOutlineEllipsisHorizontal } from "react-icons/hi2";
import { classNames } from "../../utils";
import Modal from "../ui/Modal";
import { useModal } from "../../hooks/useModal";
import {
  setting1,
  setting2,
  setting3,
  setting4,
} from "../../constants/settings";
import { startAuto } from "../../services/auto.service";
import { toast } from "react-toastify";
import { useUser } from "../../context/userContext";
import { getUserInfo } from "../../services/auth.service";

const clients = [
  {
    id: 0,
    name: "Setting 1",
    imageUrl: "/bot1.png",
    rate: "APL: 110%",
    settingLevel: "Basic Entry",
    color: "green",
    setting: setting1,
  },
  {
    id: 1,
    name: "Setting 2",
    imageUrl: "/bot1.png",
    rate: "APL: 125%",
    settingLevel: "Intermediate Strategy",
    color: "yellow",
    setting: setting2,
  },
  {
    id: 2,
    name: "Setting 3",
    imageUrl: "/bot1.png",
    rate: "APL: 140%",
    settingLevel: "Advanced Algorithm",
    color: "indigo",
    setting: setting3,
  },
  {
    id: 3,
    name: "Setting 4",
    imageUrl: "/bot1.png",
    rate: "APL: 155%",
    settingLevel: "AI-Powered Precision",
    color: "red",
    setting: setting4,
  },
];

const DrawerSettings = ({ openDrawer, setOpenDrawer }) => {
  const { open, onOpen, onClose } = useModal();
  const [setting, setSetting] = useState();
  const { handleUser } = useUser();

  const handleOnOpenMenu = (client) => {
    onOpen();
    setSetting(client);
  };

  const onSubmitAuto = async (id) => {
    try {
      const { data } = await startAuto(id);
      const user = await getUserInfo();
      if (user.data.success === 1) handleUser(user.data);
      toast("Start auto success !");
      setOpenDrawer(false);
    } catch (error) {
      toast(error.message, { type: "error" });
    }
  };

  return (
    <>
      <Drawer open={openDrawer} setOpen={setOpenDrawer}>
        <ul
          role="list"
          className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
        >
          {clients.map((client) => (
            <li
              key={client.id}
              className="overflow-hidden rounded-[39px] border border-gray-200"
            >
              <div className="flex items-center gap-x-4 border-b border-gray-900/5  p-6">
                <img
                  alt={client.name}
                  src={client.imageUrl}
                  className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                />
                <div className="text-base font-medium text-white">
                  {client.name}
                </div>
                <Menu as="div" className="relative ml-auto">
                  <MenuButton className="-m-2.5 block p-2.5 text-gray-400 hover:text-white">
                    <span className="sr-only">Open options</span>
                    <HiOutlineEllipsisHorizontal
                      aria-hidden="true"
                      className="h-5 w-5"
                    />
                  </MenuButton>
                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-0.5 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <MenuItem>
                      <div
                        className="block px-3 py-1 text-base text-gray-800 data-[focus]: data-[focus]:outline-none"
                        onClick={() => onSubmitAuto(client.id)}
                      >
                        Start auto
                      </div>
                    </MenuItem>
                    <MenuItem>
                      <div
                        onClick={() => handleOnOpenMenu(client)}
                        className="block px-3 py-1 text-base text-gray-800 data-[focus]: data-[focus]:outline-none"
                      >
                        View {client.name}
                      </div>
                    </MenuItem>
                  </MenuItems>
                </Menu>
              </div>
              <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-base">
                <div className=""></div>
                <div className="flex justify-between gap-x-4 py-3">
                  <dt className="text-white">{client.settingLevel}</dt>
                  <dd className="flex items-start gap-x-2">
                    <div
                      className={classNames(
                        `text-${client.color}-600 bg-${client.color}-100 ring-${client.color}-600`,
                        "rounded-md px-2 py-1 text-base font-medium ring-1 ring-inset "
                      )}
                    >
                      {client.rate}
                    </div>
                  </dd>
                </div>
              </dl>
            </li>
          ))}
        </ul>
      </Drawer>
      <Modal
        visible={open}
        onOk={() => onSubmitAuto(setting.id)}
        onCancel={onClose}
        title={"Settings 1"}
        okText="Start auto"
        cancelText="Close"
      >
        <div className="grid grid-cols-2 min-w-[70vw] max-h-[60vh] overflow-y-auto">
          {setting &&
            setting.setting.map((item, index) => (
              <span
                className={`text-white ${index % 2 === 0 ? "" : "text-right"}`}
                key={index}
              >
                {index + 1}: {item}
              </span>
            ))}
        </div>
      </Modal>
    </>
  );
};

export default DrawerSettings;
