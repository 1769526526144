import req from "../libs/req";

const login = async (username, password) => {
  return req.post("/login", {
    username,
    password,
  });
};

const verify2fa = async (code, username) => {
  return req.post("/login/2fa", { code, username });
};

const getUserInfo = async () => {
  return req.get("/user");
};

export { login, verify2fa, getUserInfo };
