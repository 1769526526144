import React from "react";
import { HiHome, HiOutlineInformationCircle, HiCode } from "react-icons/hi";
import { MdHistory, MdOutlineSettings } from "react-icons/md";
import { NavLink, useLocation } from "react-router-dom";
import { useUser } from "../context/userContext";
const Footer = () => {
  const location = useLocation();
  const { selectedExchange } = useUser();
  const { isLogin } = useUser();

  const footerLinks = [
    {
      title: "Information",
      link: "/info",
      icon: (
        <HiOutlineInformationCircle
          size={22}
          className={location.pathname === "/mongo" ? "w-[26px] h-[26px]" : ""}
        />
      ),
    },
    {
      title: "Home",
      link: "/",
      icon:
        selectedExchange.id === "selectex" ? (
          <>
            <HiHome
              size={20}
              className={location.pathname === "/" ? "w-[26px] h-[26px]" : ""}
            />
          </>
        ) : (
          <>
            <img
              id={selectedExchange.id}
              src={selectedExchange.icon}
              alt="selected"
              className="w-[26px]"
            />
          </>
        ),
    },
    {
      title: "History",
      link: "/history",
      icon: (
        <MdHistory
          size={20}
          className={location.pathname === "/tasks" ? "w-[26px] h-[26px]" : ""}
        />
      ),
    },

    // {
    //   title: "Settings",
    //   link: "/settings",
    //   icon: (
    //     <MdOutlineSettings
    //       size={20}
    //       className={location.pathname === "/boost" ? "w-[26px] h-[26px]" : ""}
    //     />
    //   ),
    // },
  ];

  if (!isLogin) return <></>;
  return (
    <>
      <div className="w-full z-30 flex items-center px-[8px] h-[72px] pbd-[2px] justify-center space-x-2 bg-[#212121] border-[#363636] pb-[3px] border-[1px] border-b-[#2c2b2b] rounded-[10px]">
        {footerLinks.map((footer, index) => (
          <NavLink
            id="reels"
            key={index}
            to={footer.link}
            className={({ isActive }) => {
              return `

${isActive
                  ? "w-[33%] py-3 flex flex-col h-[60px] px-[6px] mt-1 rounded-[10px] bg-cards items-center justify-center text-[#fff] text-[13px] first:rounded-tl-[22px] first:rounded-bl-[22px] last:rounded-tr-[22px] last:rounded-br-[22px]"
                  : "w-[33%] py-3 flex flex-col space-y-[2px] rounded-[10px] items-center justify-center text-[#c6c6c6] text-[13px]"
                }
  `;
            }}
          >
            <div
              id="reels2"
              className={
                location.pathname === `${footer.link}`
                  ? "space-y-[2px] flex flex-col rounded-[10px] items-center justify-center text-[#fff] text-[12px]"
                  : "flex flex-col space-y-[4px] rounded-[10px] items-center justify-center text-[#949494] text-[12px]"
              }
            >
              {footer.icon}

              <span
                className={`${location.pathname === `${footer.link}`
                  ? "text-[#fff]"
                  : "text-[#949494]"
                  } font-medium mb-[-2px]`}
              >
                {footer.title}
              </span>
            </div>
          </NavLink>
        ))}
      </div>
      <div className="bg-[#000] z-20 h-[67px] w-full fixed bottom-0 left-0 right-0 "></div>
    </>
  );
};

export default Footer;
