import React, { useEffect, useState } from "react";
import Animate from "../Animate";
import { useParams } from "react-router-dom";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import CardRound from "../CardRound";
import { calculateProfitMargin, formatMoney, formatNumber, removeDigitNumber } from "../../utils";
import Modal from "../ui/Modal";
import { useModal } from "../../hooks/useModal";
import { useUser } from "../../context/userContext";

import { toast } from "react-toastify";
import {
  getPackageBySlug,
  getPackages,
  registPackage
} from "../../services/packages.service";

const PackageInfoPage = () => {
  const { slug } = useParams();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true); // State để kiểm soát loading
  const [packageInfo, setPackageInfo] = useState({});
  const [packages, setPackages] = useState([]);
  const [isLoadPackage, setIsLoadPackage] = useState(false);
  const { open, onOpen, onClose } = useModal();
  const [isRegist, setIsRegist] = useState(true);
  const { nickName, balance } = useUser();
  const [formInputFund, setFormInputFund] = useState(0);
  const [formInputName, setFormInputName] = useState("");
  const [formInputNote, setFormInputNote] = useState("");

  const [selectedStopWinOption, setSelectedStopWinOption] = useState("");
  const [selectedStopLossOption, setSelectedStopLossOption] = useState("");

  const [isRegisting, setIsRegisting] = useState(false);

  const doInitPage = async () => {
    await fetchPackage();
    await doLoadPackage();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const doLoadPackage = async () => {
    setIsLoadPackage(true); // Kích hoạt loading
    await fetchPackages();
    setTimeout(() => {
      setIsLoadPackage(false); // Tắt loading sau 2 giây
    }, 1000);
  }

  const fetchPackages = async () => {
    try {
      const { data } = await getPackages();
      setPackages(data.result);
    } catch (error) {

    }
  }

  const fetchPackage = async () => {
    try {
      const { data } = await getPackageBySlug(slug);
      setPackageInfo(data.result);
    } catch { }

  };

  const goBack = () => {
    navigate(-1);
  };

  const gotoPackageInfo = async (slug) => {
    navigate(`/package-info/${slug}`);
    window.location.reload();
    //router.push(`/package-info/${slug}`);
  }

  const onAllFundBalance = async () => {
    setFormInputFund(removeDigitNumber(balance));
  }

  const onValidateForm = () => {
    if (Number(formInputFund) >= 1 && Number(formInputFund) < 100) {
      setIsRegist(true);
    } else {
      setIsRegist(false);
    }

    if (formInputName !== "") {
      setIsRegist(true);
    } else {
      setIsRegist(false);
    }
  }

  const onChangeAmount = (event) => {
    const value = event.target.value;

    if (!isNaN(value)) {
      setFormInputFund(value); // Cập nhật giá trị
    }

    onValidateForm();
  };

  const onChangeName = (event) => {
    const value = event.target.value;
    setFormInputName(value);
    onValidateForm();
  }

  const onchangeNote = (event) => {
    const value = event.target.value;
    setFormInputNote(value);
  }

  const handleSelectStopWinChange = (event) => {
    setSelectedStopWinOption(event.target.value);
  };

  const handleSelectStopLossChange = (event) => {
    setSelectedStopLossOption(event.target.value);
  };

  const onSubmitAuto = async () => {
    setIsRegisting(true);

    try {


      let data = await registPackage({
        packageId: packageInfo.id,
        amount: formInputFund,
        lose: selectedStopLossOption,
        win: selectedStopWinOption,
        name: formInputName,
        memo: formInputNote
      });

      toast("regist package auto success !");
      if (data.data.success === 1) {
        setTimeout(() => {
          navigate('/my-package/' + data.data.id);
        }, 1500);
      }
    } catch (error) {
      toast(error.message, { type: "error" });
    } finally {
      setTimeout(() => {
        setIsRegisting(false);
      }, 1000);
    }

  }

  const onSubmitAutoStart = async () => {
    setIsRegisting(true);

    try {

      let data = await registPackage({
        packageId: packageInfo.id,
        amount: formInputFund,
        lose: selectedStopLossOption,
        win: selectedStopWinOption,
        name: formInputName,
        memo: formInputNote,
        is_run: 1
      });

      toast("regist package auto success !");
      if (data.data.success === 1) {
        setTimeout(() => {
          navigate('/my-package/' + data.data.id);
        }, 1500);
      }
    } catch (error) {
      toast(error.message, { type: "error" });
    } finally {
      setTimeout(() => {
        setIsRegisting(false);
      }, 1000);
    }
  }

  const onOpenRegist = () => {
    setFormInputName(`${packageInfo.name}(APL: ${packageInfo.apl}%)`);
    setFormInputFund(1);
    setSelectedStopLossOption('');
    setSelectedStopWinOption('');
    onOpen();
  }

  useEffect(() => {
    doInitPage();
  }, []);

  if (loading) {
    // Hiển thị màn hình loading nếu đang tải
    return (
      <div className="flex items-center justify-center h-screen bg-[#202938]">
        <TailSpin color="#ffffff" height={50} width={50} />
      </div>
    );
  }
  return (
    <Animate>
      <div id="header" className="bg-[#202938] w-full flex fixed z-30 h-16 px-2 gap-2">
        <div className="flex items-center justify-center h-full">
          <button onClick={goBack}>
            <AiOutlineArrowLeft size={30}></AiOutlineArrowLeft>
          </button>
        </div>
        <div className="flex items-center justify-center h-full page-title">
          <span className="text-lg text-white truncate ">Package - {packageInfo.name}</span>
        </div>
      </div>
      <div className="page-body !mt-[76px] pb-[70px]">
        <div className="container mx-auto">
          <div className="package-info">
            <CardRound className={"space-y-5"} >
              <div className="py-3 card-body">
                <div className="package-detail">
                  <div className="w-16 h-16 mx-auto rounded-lg package-item-logo">
                    <img src="/logo.jpg" className="w-full h-full" />
                  </div>
                  <h6 className="mt-2 text-lg font-bold text-center text-sky-500">{packageInfo.name}(APL: {packageInfo.apl}%)</h6>
                  <div className="flex items-center justify-center">
                    <div className="flex gap-2 ">
                      <div>
                        <span className="text-xs text-gray-400">Viewed: {formatNumber(packageInfo.view)}</span>
                      </div>
                      <div>
                        <span className="text-xs text-gray-400">Used: {formatNumber(packageInfo.total_used)}</span>
                      </div>
                      <div>
                        <span className="text-xs text-gray-400">Total Balance: {formatNumber(packageInfo.total_fund, 2)}$</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-center mt-2">
                    <button
                      onClick={() => onOpenRegist()}
                      className="px-3 py-3 text-lg font-semibold leading-6 text-white uppercase rounded-lg shadow-sm w-44 bg-sky-600 hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >Regist Now!</button>
                  </div>
                </div>

                <div className="package-description border-t border-[#202938] my-6 pt-4">
                  <h6 className="text-lg text-white uppercase text-opacity-70">Auto Ai Package Description</h6>
                  <p className="text-[#999]">{packageInfo.description}</p>
                </div>
                <div className="package-description border-t border-[#202938] mt-6 pt-4">
                  <h6 className="text-lg text-white uppercase text-opacity-70">Auto AI Package Fomular</h6>
                  <div className="flex flex-col gap-2 mt-2 formular">
                    {packageInfo.formular.map((formularItem) => (
                      <div className="flex flex-row formular-item">
                        <div className="flex flex-row gap-2">
                          {formularItem.command.split('').map((forChar) => (
                            <div className="px-2 border ">{forChar}</div>
                          ))}
                        </div>
                        <div className="flex items-center justify-center ">
                          <AiOutlineArrowRight size={24}></AiOutlineArrowRight>
                        </div>
                        <div>
                          <div className="px-2 border ">{formularItem.result}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </CardRound>
          </div>
          <div className="mt-2 package-used">
            <CardRound className={"space-y-5"} >
              <h6 className="text-lg text-white uppercase text-opacity-70">Used Users</h6>
              <div className="mt-2 package-used-users">
                <div className="text-center">No data found</div>
              </div>
            </CardRound>
          </div>
          <div className="mt-2 other-packages">
            <CardRound className={"space-y-5"} >
              <h6 className="text-lg text-white text-opacity-70">OTHER PACKAGES</h6>
              <div className="flex flex-col w-full gap-2 package-items">
                {packages.filter((item) => {
                  return item.id !== packageInfo.id;
                }).map((packageItem) => (

                  <div className="flex items-center package-item bg-[#202938] w-full rounded-lg p-2">
                    <div className="w-16 h-16 rounded-lg package-item-logo">
                      <img src="/logo.jpg" className="w-full h-full" />
                    </div>
                    <div className="flex flex-col items-start justify-center flex-1 px-2 package-item-content">
                      <h6 className="text-sm font-semibold">{packageItem.name} (APL: {packageItem.apl}%)</h6>
                      <p className="text-xs text-gray-400">
                        {packageItem.description}
                      </p>
                      <div className="flex w-full gap-2">
                        <div>
                          <span className="text-xs text-gray-400">Viewed: {formatNumber(packageItem.view)}</span>
                        </div>
                        <div>
                          <span className="text-xs text-gray-400">Used: {formatNumber(packageItem.total_used)}</span>
                        </div>
                        <div>
                          <span className="text-xs text-gray-400">Total Balance: {formatNumber(packageItem.total_fund, 2)}$</span>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center w-16 h-16 package-item-action">
                      <button
                        onClick={() => gotoPackageInfo(packageItem.slug)}
                        className="px-4 py-2 text-sm font-semibold leading-6 text-white rounded-lg shadow-sm bg-sky-600 hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >Start</button>
                    </div>
                  </div>
                ))}
              </div>
            </CardRound>
          </div>
        </div>
      </div>
      <Modal
        visible={open}
        onOk={() => onSubmitAuto()}
        onCancel={onClose}
        onSecond={() => onSubmitAutoStart()}
        title={`Package - ${formInputName}`}
        okText="Regist"
        cancelText="Close"
        isEnableOk={isRegist}
        secondText="Regist & Start"
        isEnableSecond={isRegist}
        disabledAll={isRegisting}
      >
        <div className="relative w-full">
          <div className="  max-h-[60vh] overflow-y-auto w-full">
            <div className="flex flex-col gap-1 mb-2 form-item">
              <label for="fundAmount" className="block font-medium text-md ">
                Amount Factor<span className="ml-1 text-red-500">*</span>
              </label>
              <div className="flex items-center px-2 py-2 bg-white border border-gray-300 rounded-md form-input focus-within:ring-2 focus-within:ring-blue-500">
                <span className="mr-2 text-sm font-medium text-gray-500">$</span>
                <input
                  type="number"
                  placeholder="Enter amount"
                  value={formInputFund}
                  min={1}
                  max={100}
                  onChange={onChangeAmount}
                  className="flex-1 text-gray-700 placeholder-gray-400 bg-transparent focus:outline-none"
                />
              </div>
              <p className="text-sm text-orange-500">
                The amount coefficient corresponds to the amount of money on 1 command(min: 1$, max: 99$)
              </p>
            </div>
            <div className="flex flex-col gap-1 mb-2 form-item">
              <label for="fundAmount" className="block font-medium text-md ">
                Stop Win Factor
              </label>
              <div className="relative bg-white border border-gray-300 rounded-lg focus-within:ring-2 focus-within:ring-blue-500">
                <select
                  value={selectedStopWinOption}
                  onChange={handleSelectStopWinChange}
                  className="block w-full px-2 py-2 text-gray-700 bg-transparent appearance-none focus:outline-none"
                >
                  <option value="" disabled>
                    -- Select stop win factor --
                  </option>
                  <option value="5">x5 | {formatNumber(formInputFund * 5, 2)}$</option>
                  <option value="10">x10 | {formatNumber(formInputFund * 10, 2)}$</option>
                  <option value="15">x15 | {formatNumber(formInputFund * 15, 2)}$</option>
                  <option value="20">x20 | {formatNumber(formInputFund * 20, 2)}$</option>
                  <option value="25">x25 | {formatNumber(formInputFund * 25, 2)}$</option>
                  <option value="30">x30 | {formatNumber(formInputFund * 30, 2)}$</option>
                </select>

                {/* Arrow Icon */}
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 text-gray-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-1 mb-2 form-item">
              <label for="fundAmount" className="block font-medium text-md ">
                Stop Loss Factor
              </label>
              <div className="relative bg-white border border-gray-300 rounded-lg focus-within:ring-2 focus-within:ring-blue-500">
                <select
                  value={selectedStopLossOption}
                  onChange={handleSelectStopLossChange}
                  className="block w-full px-2 py-2 text-gray-700 bg-transparent appearance-none focus:outline-none"
                >
                  <option value="" disabled>
                    -- Select stop loss factor --
                  </option>
                  <option value="5">x5 | {formatNumber(formInputFund * 5, 2)}$</option>
                  <option value="10">x10 | {formatNumber(formInputFund * 10, 2)}$</option>
                  <option value="15">x15 | {formatNumber(formInputFund * 15, 2)}$</option>
                  <option value="20">x20 | {formatNumber(formInputFund * 20, 2)}$</option>
                  <option value="25">x25 | {formatNumber(formInputFund * 25, 2)}$</option>
                  <option value="30">x30 | {formatNumber(formInputFund * 30, 2)}$</option>
                  <option value="40">x40 | {formatNumber(formInputFund * 40, 2)}$</option>
                  <option value="50">x50 | {formatNumber(formInputFund * 50, 2)}$</option>
                  <option value="75">x75 | {formatNumber(formInputFund * 75, 2)}$</option>
                  <option value="100">x100 | {formatNumber(formInputFund * 100, 2)}$</option>
                </select>

                {/* Arrow Icon */}
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 text-gray-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-1 mb-2 form-item">
              <label className="block font-medium text-md ">
                Name<span className="ml-1 text-red-500">*</span>
              </label>
              <div className="flex items-center px-2 py-2 bg-white border border-gray-300 rounded-md form-input focus-within:ring-2 focus-within:ring-blue-500">
                <input
                  value={formInputName}
                  onChange={onChangeName}
                  type="text"
                  placeholder="Enter name"
                  className="flex-1 text-gray-700 placeholder-gray-400 bg-transparent focus:outline-none"
                />
              </div>
            </div>
            <div className="flex flex-col gap-1 mb-2 form-item">
              <label className="block font-medium text-md ">
                Note
              </label>
              <div className="flex items-center px-2 py-2 bg-white border border-gray-300 rounded-md form-input focus-within:ring-2 focus-within:ring-blue-500">
                <textarea
                  value={formInputNote}
                  onChange={onchangeNote}
                  placeholder="Enter note"
                  className="flex-1 text-gray-700 placeholder-gray-400 bg-transparent resize-none focus:outline-none"
                  rows="3"
                ></textarea>
              </div>
            </div>
          </div>
          {isRegisting ? (<div className="flex absolute items-center justify-center  bg-[#202938] size-full top-0 left-0 opacity-55">
            <TailSpin color="#ffffff" height={50} width={50} />
          </div>) : (
            <></>
          )}
        </div>
      </Modal>
    </Animate>
  );
};

export default PackageInfoPage;
