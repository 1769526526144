export const setting1 = [
  "BBBB-S",
  "BBBS-B",
  "BBSB-S",
  "BBSS-B",
  "BSBB-S",
  "BSBS-B",
  "BSSB-S",
  "BSSS-B",
  "SBBB-S",
  "SBBS-B",
  "SBSB-S",
  "SBSS-B",
  "SSBB-S",
  "SSBS-B",
  "SSSB-S",
  "SSSS-B",
];

export const setting2 = [
  "BBBBB-S",
  "BBBBS-B",
  "BBBSB-S",
  "BBBSS-B",
  "BBSBB-S",
  "BBSBS-B",
  "BBSSB-S",
  "BBSSS-B",
  "BSBBB-S",
  "BSBBS-B",
  "BSBSB-S",
  "BSBSS-B",
  "BSSBB-S",
  "BSSBS-B",
  "BSSSB-S",
  "BSSSS-B",
  "SBBBB-S",
  "SBBBS-B",
  "SBBSB-S",
  "SBBSS-B",
  "SBSBB-S",
  "SBSBS-B",
  "SBSSB-S",
  "SBSSS-B",
  "SSBBB-S",
  "SSBBS-B",
  "SSBSB-S",
  "SSBSS-B",
  "SSSBB-S",
  "SSSBS-B",
  "SSSSB-S",
  "SSSSS-B",
];

export const setting3 = [
  "BBBBBB-S",
  "BBBBBS-B",
  "BBBBSB-S",
  "BBBBSS-B",
  "BBBSBB-S",
  "BBBSBS-B",
  "BBBSSB-S",
  "BBBSSS-B",
  "BBSBBB-S",
  "BBSBBS-B",
  "BBSBSB-S",
  "BBSBSS-B",
  "BBSSBB-S",
  "BBSSBS-B",
  "BBSSSB-S",
  "BBSSSS-B",
  "BSBBBB-S",
  "BSBBBS-B",
  "BSBBSB-S",
  "BSBBSS-B",
  "BSBSBB-S",
  "BSBSBS-B",
  "BSBSSB-S",
  "BSBSSS-B",
  "BSSBBB-S",
  "BSSBBS-B",
  "BSSBSB-S",
  "BSSBSS-B",
  "BSSSBB-S",
  "BSSSBS-B",
  "BSSSSB-S",
  "BSSSSS-B",
  "SBBBBB-S",
  "SBBBBS-B",
  "SBBBSB-S",
  "SBBBSS-B",
  "SBBSBB-S",
  "SBBSBS-B",
  "SBBSSB-S",
  "SBBSSS-B",
  "SBSBBB-S",
  "SBSBBS-B",
  "SBSBSB-S",
  "SBSBSS-B",
  "SBSSBB-S",
  "SBSSBS-B",
  "SBSSSB-S",
  "SBSSSS-B",
  "SSBBBB-S",
  "SSBBBS-B",
];

export const setting4 = [
  "BBBBBBB-S",
  "BBBBBBS-B",
  "BBBBBSB-S",
  "BBBBBSS-B",
  "BBBBSBB-S",
  "BBBBSBS-B",
  "BBBBSSB-S",
  "BBBBSSS-B",
  "BBBSBBB-S",
  "BBBSBBS-B",
  "BBBSBSB-S",
  "BBBSBSS-B",
  "BBBSSBB-S",
  "BBBSSBS-B",
  "BBBSSSB-S",
  "BBBSSSS-B",
  "BBSBBBB-S",
  "BBSBBBS-B",
  "BBSBBSB-S",
  "BBSBBSS-B",
  "BBSBSBB-S",
  "BBSBSBS-B",
  "BBSBSSB-S",
  "BBSBSSS-B",
  "BBSSBBB-S",
  "BBSSBBS-B",
  "BBSSBSB-S",
  "BBSSBSS-B",
  "BBSSSBB-S",
  "BBSSSBS-B",
  "BBSSSSB-S",
  "BBSSSSS-B",
  "BSBBBBB-S",
  "BSBBBBS-B",
  "BSBBBSB-S",
  "BSBBBSS-B",
  "BSBBSBB-S",
  "BSBBSBS-B",
  "BSBBSSB-S",
  "BSBBSSS-B",
  "BSBSBBB-S",
  "BSBSBBS-B",
  "BSBSBSB-S",
  "BSBSBSS-B",
  "BSBSSBB-S",
  "BSBSSBS-B",
  "BSBSSSB-S",
  "BSBSSSS-B",
  "BSSBBBB-S",
  "BSSBBBS-B",
  "BSSBBSB-S",
  "BSSBBSS-B",
  "BSSBSBB-S",
  "BSSBSBS-B",
  "BSSBSSB-S",
  "BSSBSSS-B",
  "BSSSBBB-S",
  "BSSSBBS-B",
  "BSSSBSB-S",
  "BSSSBSS-B",
  "BSSSSBB-S",
  "BSSSSBS-B",
  "BSSSSSB-S",
  "BSSSSSS-B",
  "SBBBBBB-S",
  "SBBBBBS-B",
  "SBBBBSB-S",
  "SBBBBSS-B",
  "SBBBSBB-S",
  "SBBBSBS-B",
  "SBBBSSB-S",
  "SBBBSSS-B",
  "SBBSBBB-S",
  "SBBSBBS-B",
  "SBBSBSB-S",
  "SBBSBSS-B",
  "SBBSSBB-S",
  "SBBSSBS-B",
  "SBBSSSB-S",
  "SBBSSSS-B",
  "SBSBBBB-S",
  "SBSBBBS-B",
  "SBSBBSB-S",
  "SBSBBSS-B",
  "SBSBSBB-S",
  "SBSBSBS-B",
  "SBSBSSB-S",
  "SBSBSSS-B",
  "SBSSBBB-S",
  "SBSSBBS-B",
  "SBSSBSB-S",
  "SBSSBSS-B",
  "SBSSSBB-S",
  "SBSSSBS-B",
  "SBSSSSB-S",
  "SBSSSSS-B",
  "SSBBBBB-S",
  "SSBBBBS-B",
  "SSBBBSB-S",
  "SSBBBSS-B",
];

export const SETTING_INFO = {
  0: {
    label: "Basic Entry",
    rate: "110%",
  },
  1: {
    label: "Intermediate Strategy",
    rate: "125%",
  },
  2: {
    label: "Advanced Algorithm",
    rate: "140%",
  },
  3: {
    label: "AI-Powered Precision",
    rate: "155%",
  },
};
