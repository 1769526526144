import React, { useEffect, useState } from "react";
import { useUser } from "../context/userContext";
import { useNavigate } from "react-router-dom";
import {
  getHistoryDaily,
  stopAuto,
} from "../services/auto.service";
import {
  getPackages,
  getUsePackages
} from "../services/packages.service";
import { calculateProfitMargin, formatMoney, formatNumber } from "../utils";
import Button from "./Button";
import CardRound from "./CardRound";
import DrawerSettings from "./Home/DrawerSettings";
import { toast } from "react-toastify";
import { getUserInfo } from "../services/auth.service";


const HomeComp = () => {
  const { userAi, handleUser } = useUser();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [historyDaily, setHistoryDaily] = useState([]);
  const [packages, setPackages] = useState([]);
  const [isLoadPackage, setIsLoadPackage] = useState(false);

  const [userPackages, setUserPackages] = useState([]);
  const [isLoadUserPackage, setIsLoadUserPackage] = useState(false);

  const navigate = useNavigate(); // Hook để điều hướng

  const fetchDaily = async () => {
    try {
      const { data } = await getHistoryDaily();
      setHistoryDaily(data.result);
    } catch { }
  };

  const doLoadPackage = async () => {
    setIsLoadPackage(true); // Kích hoạt loading
    await fetchPackages();
    setTimeout(() => {
      // Giả lập quá trình tải dữ liệu
      setIsLoadPackage(false); // Tắt loading sau 2 giây
    }, 1000);
  }

  const fetchPackages = async () => {
    try {
      const { data } = await getPackages();
      setPackages(data.result);
    } catch (error) {

    }
  }

  const doLoadUserPackage = async () => {
    setIsLoadUserPackage(true); // Kích hoạt loading
    await fetchUserPackage();
    setTimeout(() => {
      // Giả lập quá trình tải dữ liệu
      setIsLoadUserPackage(false); // Tắt loading sau 2 giây
    }, 1000);
  }

  const fetchUserPackage = async () => {
    try {
      const { data } = await getUsePackages(1, 2);
      setUserPackages(data.result);
    } catch (error) {

    }
  }

  const gotoPackageInfo = async (slug) => {
    navigate(`package-info/${slug}`);
  }

  const gotoUserPackage = async (id) => {
    navigate(`/my-package/${id}`);
  }

  const gotoAllPackage = async () => {
    navigate(`my-packages`);
  }

  useEffect(() => {
    fetchDaily();
    doLoadPackage();
    doLoadUserPackage();
  }, []);

  return (
    <div className="container mx-auto">
      <div className="flex flex-col gap-2">
        <div className="flex flex-row gap-2 statistics">
          <div className="flex-1 statistic">
            <CardRound >
              <div className="flex flex-col items-center justify-center">
                <span className="text-center text-gray-200 uppercase">Win/Lose</span>
                <div className="text-lg text-center">
                  <span className="font-bold text-green-400">{userAi.win}</span>&nbsp;/&nbsp;<span className="font-bold text-rose-500">{userAi.lose}</span>
                </div>
              </div>

            </CardRound>
          </div>
          <div className="flex-1 statistic">
            <CardRound  >
              <div className="flex flex-col items-center justify-center">
                <span className="text-center text-gray-200 uppercase">Profit</span>
                <div className="text-lg text-center">
                  {
                    userAi.profit > 0
                      ? (<span className="text-green-400 ">{formatMoney(userAi.profit)}</span>)
                      : (<span className="text-rose-500 ">{formatMoney(userAi.profit)}</span>)
                  }
                </div>
              </div>
            </CardRound>
          </div>
        </div>
        {userPackages.length > 0 ? (
          <div className="packages">
            <CardRound loading={isLoadUserPackage}>
              <div className="flex flex-row justify-between card-title">
                <h3 className="font-bold text-white uppercase ">
                  Your packages
                </h3>
                <button className="text-sky-600" onClick={gotoAllPackage}>View all</button>
              </div>
              <div className="mt-2 card-body">
                <div className="flex flex-col w-full gap-2 package-items">
                  {userPackages.map((packageItem) => (
                    <div className="kanban-item">
                      <a className="kanban-box" href="javascript:void(0)" onClick={() => gotoUserPackage(packageItem.id)}>
                        <div>
                          <span className="text-sm">{packageItem.created_at}</span>
                          {packageItem.is_run == 1
                            ?
                            (<span className="float-right bg-teal-600 badge">Active</span>)
                            :
                            (<span className="float-right bg-orange-600 badge">Unactive</span>)
                          }
                        </div>
                        <h6 className="text-lg font-bold text-white">{packageItem.name}</h6>
                        <div className="media-body text-md">
                          <p>{packageItem.memo}</p>
                        </div>
                        <div className="flex w-full gap-2 mt-2">
                          <div className="flex flex-col flex-1  bg-[#4c535f] rounded-md shadow-lg py-1">
                            <span className="text-sm font-bold text-center text-white">Profit</span>
                            <span className="text-sm text-center text-gray-400">
                              {packageItem.profit >= 0
                                ?
                                (<span className="text-green-600">+{formatNumber(packageItem.profit, 2)}$</span>)
                                :
                                (<span className="text-rose-600">{formatNumber(packageItem.profit, 2)}$</span>)
                              }
                            </span>
                          </div>
                          <div className="flex flex-col flex-1  bg-[#4c535f] rounded-md shadow-lg  py-1">
                            <span className="text-sm font-bold text-center text-white">Profit today</span>
                            <span className="text-sm text-center text-gray-400">
                              {packageItem.today_profit >= 0
                                ?
                                (<span className="text-green-600">+{formatNumber(packageItem.today_profit, 2)}$</span>)
                                :
                                (<span className="text-rose-600">{formatNumber(packageItem.today_profit, 2)}$</span>)
                              }
                            </span>
                          </div>
                          <div className="flex flex-col flex-1  bg-[#4c535f] rounded-md shadow-lg  py-1">
                            <span className="text-sm font-bold text-center text-white">Win/Lose</span>
                            <span className="text-sm text-center text-gray-400">{packageItem.win}/{packageItem.lose}</span>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </CardRound>
          </div>
        ) : (<></>)}

        <div className="packages">
          <CardRound loading={isLoadPackage}>
            <div className="flex flex-col justify-between card-title">
              <h3 className="font-bold text-white uppercase ">
                Packages
              </h3>
            </div>
            <div className="mt-2 card-body">
              <div className="flex flex-col w-full gap-2 package-items">
                {packages.map((packageItem) => (
                  <div className="flex items-center package-item bg-[#202938] w-full rounded-lg p-2">
                    <div className="w-16 h-16 rounded-lg package-item-logo">
                      <img src="/logo.jpg" className="w-full h-full" />
                    </div>
                    <div className="flex flex-col items-start justify-center flex-1 px-2 package-item-content">
                      <h6 className="text-sm font-semibold">{packageItem.name} (APL: {packageItem.apl}%)</h6>
                      <p className="text-xs text-gray-400">
                        {packageItem.description}
                      </p>
                      <div className="flex w-full gap-2">
                        <div>
                          <span className="text-xs text-gray-400">Viewed: {formatNumber(packageItem.view)}</span>
                        </div>
                        <div>
                          <span className="text-xs text-gray-400">Used: {formatNumber(packageItem.total_used)}</span>
                        </div>
                        <div>
                          <span className="text-xs text-gray-400">Total Balance: {formatNumber(packageItem.total_fund, 2)}$</span>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center w-16 h-16 package-item-action">
                      <button
                        onClick={() => gotoPackageInfo(packageItem.slug)}
                        className="px-4 py-2 text-sm font-semibold leading-6 text-white rounded-lg shadow-sm bg-sky-600 hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >Start</button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </CardRound>
        </div>
        <DrawerSettings openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
      </div>
    </div>
  );
};

export default HomeComp;
