import React from "react";

const CardRound = ({ children, className, loading }) => {
  return (
    <div className={`overflow-hidden rounded-[10px] bg-[#111727] card relative`}>
      {loading && (
        <div className="absolute inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
          <div className="loader"></div> {/* Thêm loader hoặc hiệu ứng tùy ý */}
        </div>
      )}
      <div className={`px-4 py-4 sm:p-6 ${className ?? ""} ${loading ? "opacity-50" : ""}`}>{children}</div>
    </div>
  );
};

export default CardRound;
