import { useState } from "react";

/**
 *
 * @param defaultModal
 * @returns handle modal
 */
export const useModal = (defaultModal = false) => {
  const [open, setOpen] = useState(defaultModal);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  return { open, onOpen, onClose };
};
