import React, { useState, useEffect, useContext, createContext } from "react";

const AuthContext = createContext();

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.hasOwnProperty("tokenUser");
  });

  const doLogin = () => setIsLoggedIn(true); // Hàm để đăng nhập
  const doLogout = () => setIsLoggedIn(false); // Hàm để đăng xuất

  return (
    <AuthContext.Provider value={{ user, loading, isLoggedIn, doLogin, doLogout }}>
      {children}
    </AuthContext.Provider>
  );
};
