import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Main from "./pages/Main";
import MainFullScreen from "./pages/MainFullScreen";
import Home from "./pages/Home";
import ErrorCom from "./Components/ErrorCom";
import { AuthContextProvider } from "./context/AuthContext";
import HistoryPage from "./Components/History";
import SettingsPage from "./Components/Settings";
import InformationPage from "./Components/Information";
import LoginPage from "./Components/Login";
import ProtectedRoute from "./Components/ProtectedRoute";
import PackageInfoPage from "./Components/PackageInfo";
import MyPackagePage from "./Components/PackageInfo/MyPackage";
import MyPackageInfoPage from "./Components/PackageInfo/MyPackageInfo";

const router = createBrowserRouter([
  {
    path: "/info",
    element: (
      <ProtectedRoute>
        <Main />
      </ProtectedRoute>
    ),
    errorElement: <ErrorCom />,
    children: [
      {
        path: "/info",
        element: <InformationPage />,
      },
    ],
  },
  {
    path: "/login",
    errorElement: <ErrorCom />,
    children: [
      {
        path: "/login",
        element: <LoginPage />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Main />
      </ProtectedRoute>
    ),
    errorElement: <ErrorCom />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
    ],
  },
  {
    path: "/history",
    element: (
      <ProtectedRoute>
        <Main />
      </ProtectedRoute>
    ),
    errorElement: <ErrorCom />,
    children: [
      {
        path: "/history",
        element: <HistoryPage />,
      },
    ],
  },
  {
    path: "/settings",
    element: (
      <ProtectedRoute>
        <Main />
      </ProtectedRoute>
    ),
    errorElement: <ErrorCom />,
    children: [
      {
        path: "/settings",
        element: <SettingsPage />,
      },
    ],
  },
  {
    path: "/my-packages",
    element: (
      <ProtectedRoute>
        <MainFullScreen />
      </ProtectedRoute>
    ),
    errorElement: <ErrorCom />,
    children: [
      {
        path: "/my-packages",
        element: <MyPackagePage />,
      },
    ],
  },
  {
    path: "/package-info/:slug",
    element: (
      <ProtectedRoute>
        <MainFullScreen />
      </ProtectedRoute>
    ),
    errorElement: <ErrorCom />,
    children: [
      {
        path: "/package-info/:slug",
        element: <PackageInfoPage />,
      },
    ],
  },
  {
    path: "/my-package/:id",
    element: (
      <ProtectedRoute>
        <MainFullScreen />
      </ProtectedRoute>
    ),
    errorElement: <ErrorCom />,
    children: [
      {
        path: "/my-package/:id",
        element: <MyPackageInfoPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthContextProvider>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </AuthContextProvider>
);
