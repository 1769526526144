import React from "react";
import Animate from "../Animate";

const SettingsPage = () => {
  return (
    <Animate>
      <div className="bg-black h-screen w-full">
        <div className="flex flex-col justify-center w-full space-y-3 px-3 pt-4">
          <div className="">
            <h2 className="text-center mb-4 text-gray-200">Settings</h2>
          </div>
        </div>
      </div>
    </Animate>
  );
};

export default SettingsPage;
