import req from "../libs/req";

const getPackages = async () => {
    return req.get("/packages");
};

const getPackage = async (packageId) => {
    return req.get(`/package?packageId=${packageId}`);
};

const getPackageBySlug = async (slug) => {
    return req.get(`/package?slug=${slug}`);
};

const registPackage = async (data) => {
    return req.post("/regist-package", data);
};

const getUsePackages = async (page = 1, take = 10) => {
    return req.get(`/user-packages?pageIndex=${page}&pageSize=${take}`);
};

const getUsePackage = async (packageId) => {
    return req.get(`/user-package?userPackageId=${packageId}`);
};

const getUsePackageHistory = async (packageId) => {
    return req.get(`/bet-history-package?userPackageId=${packageId}`);
};

const savePackage = async (data) => {
    return req.post("/save-package", data);
};
export {
    getPackages,
    getPackage,
    getPackageBySlug,
    registPackage,
    getUsePackages,
    getUsePackage,
    getUsePackageHistory,
    savePackage
};