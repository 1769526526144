import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const UserContext = createContext();
export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children, user }) => {
  const [balance, setBalance] = useState(0);
  const [email, setEmail] = useState("");
  const [nickName, setNickName] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  const [isLogin2fa, setIsLogin2fa] = useState(false);
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const telegramUser = window.Telegram.WebApp.initDataUnsafe?.user;
  const [characterMenu, setCharacterMenu] = useState(false);
  const [selectedExchange, setSelectedExchange] = useState({
    id: "selectex",
    icon: "/exchange.svg",
    name: "Select exchange",
  });
  const [userAi, setUserAi] = useState();
  const [loading, setLoading] = useState(true);

  const handleUser = (user) => {
    setIsLogin(true);
    setEmail(user.user.email);
    setNickName(user.user.nick_name);
    setBalance(user.user.balance);
    setUserAi(user.userAi);
  };

  useEffect(() => {
    let token = localStorage.hasOwnProperty("tokenUser");

    if(token){
      //get user info
      setIsLogin(true);
    }
    if (user) handleUser(user);

    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [user]);
    

  return (
    <UserContext.Provider
      value={{
        balance,
        email,
        nickName,
        loading,
        setLoading,
        initialized,
        setInitialized,
        telegramUser,
        characterMenu,
        setCharacterMenu,
        selectedExchange,
        setSelectedExchange,
        isLogin,
        isLogin2fa,
        setIsLogin,
        setNickName,
        userAi,
        handleUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
