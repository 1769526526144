import React, { useEffect, useState } from "react";
import Animate from "../Animate";
import { useParams } from "react-router-dom";
import { AiOutlineArrowLeft, AiOutlineArrowRight, AiFillClockCircle, AiFillAlert, AiFillDollarCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import CardRound from "../CardRound";
import { calculateProfitMargin, formatMoney, formatNumber } from "../../utils";
import {
    getUsePackage,
    getUsePackageHistory,
    savePackage
} from "../../services/packages.service";
import { startAuto, stopAuto } from "../../services/auto.service";
import { toast } from "react-toastify";
import { useModal } from "../../hooks/useModal";
import Modal from "../ui/Modal";

const MyPackageInfoPage = () => {
    const { id } = useParams();
    let navigate = useNavigate();
    const [loading, setLoading] = useState(true); // State để kiểm soát loading
    const [userPackage, setUserPackage] = useState(null);
    const [userPackageHistories, setUserPackageHistories] = useState([]);
    const [isLoadUserPackageHistories, setIsLoadUserPackageHistories] = useState(false);
    const { open, onOpen, onClose } = useModal();
    const [formInputFund, setFormInputFund] = useState(0);
    const [formInputName, setFormInputName] = useState("");
    const [formInputNote, setFormInputNote] = useState("");
    const [isFormValidate, setIsFormValidate] = useState(true);

    const [isSaving, setIsSaving] = useState(false);
    const [selectedStopWinOption, setSelectedStopWinOption] = useState("");
    const [selectedStopLossOption, setSelectedStopLossOption] = useState("");

    const doInitPage = async () => {
        await doLoadUserPackage();
        doLoadUserPackageHistories();
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    const onValidateForm = () => {
        if (Number(formInputFund) >= 1 && Number(formInputFund) < 100) {
            setIsFormValidate(true);
        } else {
            setIsFormValidate(false);
        }

        if (formInputName !== "") {
            setIsFormValidate(true);
        } else {
            setIsFormValidate(false);
        }
    }

    const onChangeAmount = (event) => {
        const value = event.target.value;

        if (!isNaN(value)) {
            setFormInputFund(value); // Cập nhật giá trị
        }

        onValidateForm();
    };

    const onChangeName = (event) => {
        const value = event.target.value;
        setFormInputName(value);
        onValidateForm();
    }

    const onchangeNote = (event) => {
        const value = event.target.value;
        setFormInputNote(value);
    }

    const handleSelectStopWinChange = (event) => {
        setSelectedStopWinOption(event.target.value);
    };

    const handleSelectStopLossChange = (event) => {
        setSelectedStopLossOption(event.target.value);
    };

    const doLoadUserPackage = async () => {
        await fetchUserPackage();
    }

    const fetchUserPackage = async () => {
        try {
            const { data } = await getUsePackage(id);
            setUserPackage(data.result);
        } catch (error) {

        }
    }

    const doLoadUserPackageHistories = async () => {
        setIsLoadUserPackageHistories(true);
        await fetchUserPackageHistories();
        setTimeout(() => {
            // Giả lập quá trình tải dữ liệu
            setIsLoadUserPackageHistories(false); // Tắt loading sau 2 giây
        }, 1000);
    }

    const fetchUserPackageHistories = async () => {
        try {
            const { data } = await getUsePackageHistory(id);
            setUserPackageHistories(data.result);
        } catch (error) {

        }
    }

    const handlerClickStop = async () => {
        try {
            await stopAuto(userPackage.id);

            toast("Stop auto success !");
            setTimeout(() => {
                setUserPackage({
                    ...userPackage,
                    is_run: 0
                });
            }, 500);
        } catch (error) {
            toast(error.message, { type: "error" });
        }
    }

    const handlerClickStart = async () => {
        try {
            await startAuto(userPackage.id);

            toast("Start auto success !");
            setTimeout(() => {
                setUserPackage({
                    ...userPackage,
                    is_run: 1
                });
            }, 500);
        } catch (error) {
            toast(error.message, { type: "error" });
        }
    }

    const onOpenEdit = () => {
        setFormInputName(`${userPackage.name}`);
        setFormInputFund(formatNumber(userPackage.amount,0));
        setSelectedStopLossOption(formatNumber(userPackage.day_win,0));
        setSelectedStopWinOption(formatNumber(userPackage.day_lose,0));
        setFormInputNote(userPackage.memo ? userPackage.memo : "");
        onOpen();
    }

    const onSaveAuto = async () => {
        setIsSaving(true);

        try {

            await savePackage({
                id: userPackage.id,
                amount: formInputFund,
                lose: selectedStopLossOption,
                win: selectedStopWinOption,
                name: formInputName,
                memo: formInputNote
            });

            toast("regist package auto success !");

            setUserPackage({
                ...userPackage,
                amount: formInputFund,
                memo: formInputNote,
                name: formInputName,
                day_lose: selectedStopLossOption,
                day_win: selectedStopWinOption
            });
            onClose();

        } catch (error) {
            toast(error.message, { type: "error" });
        } finally {
            setTimeout(() => {
                setIsSaving(false);
            }, 1000);
        }

    }

    const onSaveAutoStart = async () => {
        setIsSaving(true);

        try {
            await savePackage({
                id: userPackage.id,
                amount: formInputFund,
                lose: selectedStopLossOption,
                win: selectedStopWinOption,
                name: formInputName,
                memo: formInputNote,
                is_run: 1
            });
            toast("regist package auto success !");

            setUserPackage({
                ...userPackage,
                amount: formInputFund,
                memo: formInputNote,
                name: formInputName,
                day_lose: selectedStopLossOption,
                day_win: selectedStopWinOption,
                is_run: 1
            });

            onClose();
        } catch (error) {
            toast(error.message, { type: "error" });
        } finally {
            setTimeout(() => {
                setIsSaving(false);
            }, 1000);
        }

    }


    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        doInitPage();
    }, []);

    if (loading) {
        // Hiển thị màn hình loading nếu đang tải
        return (
            <div className="flex items-center justify-center h-screen bg-[#202938]">
                <TailSpin color="#ffffff" height={50} width={50} />
            </div>
        );
    }

    return (
        <Animate>
            <div id="header" className="bg-[#202938] w-full flex fixed z-30 h-16 px-2 gap-2">
                <div className="flex items-center justify-center h-full">
                    <button onClick={goBack}>
                        <AiOutlineArrowLeft size={30}></AiOutlineArrowLeft>
                    </button>
                </div>
                <div className="flex items-center justify-center flex-1 h-full page-title max-w-[90%]">
                    <span className="block w-full mr-2 text-lg text-white truncate">Auto Package - {userPackage.name}</span>
                </div>
            </div>
            <div className="page-body !mt-[76px] pb-[70px]">
                <div className="container px-4 mx-auto">
                    <div className="package-info">
                        <CardRound >
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-row items-center text-sm"><AiFillClockCircle size={14} className="mr-1"></AiFillClockCircle>{userPackage.created_at}</div>
                                {userPackage.is_run == 1
                                    ?
                                    (<span className="bg-teal-600 badge">Active</span>)
                                    :
                                    (<span className="bg-orange-600 badge">Unactive</span>)
                                }
                            </div>

                            <h2 className="text-lg font-bold">{userPackage.name}</h2>
                            <p>{userPackage.memo}</p>
                        </CardRound>
                    </div>
                    <div className="mt-2">
                        <div className="flex gap-4">
                            <button
                                type="button"
                                disabled={userPackage.is_run === 1}
                                className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-sky-500 hover:bg-sky-400 disabled:bg-sky-700 disabled:opacity-55 disabled:cursor-not-allowed sm:ml-3 sm:w-auto "
                                onClick={handlerClickStart}
                            >
                                Start Auto
                            </button>
                            <button
                                type="button"
                                disabled={Number(userPackage.is_run) === 0}
                                className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-orange-500 rounded-md shadow-sm hover:bg-orange-400 disabled:bg-orange-800 disabled:opacity-55 disabled:cursor-not-allowed sm:ml-3 sm:w-auto "
                                onClick={handlerClickStop}
                            >
                                Stop Auto
                            </button>
                            <button
                                type="button"
                                disabled={userPackage.is_run === 1}
                                className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-teal-500 rounded-md shadow-sm hover:bg-teal-400 disabled:bg-teal-700 disabled:opacity-55 disabled:cursor-not-allowed sm:ml-3 sm:w-auto "
                                onClick={onOpenEdit}
                            >
                                Edit Auto
                            </button>
                        </div>
                    </div>
                    <div className="mt-2">
                        <CardRound>
                            <div className="card-title">
                                <h3 className="font-bold text-white uppercase ">
                                    Statistics
                                </h3>
                            </div>
                            <div className="flex w-full gap-2 mt-2">
                                <div className="flex flex-col flex-1  bg-[#4c535f] rounded-md shadow-lg py-1">
                                    <span className="text-sm font-bold text-center text-white">Profit</span>
                                    <span className="text-sm text-center text-gray-400">
                                        {userPackage.profit >= 0
                                            ?
                                            (<span className="text-green-600">+{formatNumber(userPackage.profit, 2)}$</span>)
                                            :
                                            (<span className="text-rose-600">{formatNumber(userPackage.profit, 2)}$</span>)
                                        }
                                    </span>
                                </div>
                                <div className="flex flex-col flex-1  bg-[#4c535f] rounded-md shadow-lg  py-1">
                                    <span className="text-sm font-bold text-center text-white">Profit today</span>
                                    <span className="text-sm text-center text-gray-400">
                                        {userPackage.today_profit >= 0
                                            ?
                                            (<span className="text-green-600">+{formatNumber(userPackage.today_profit, 2)}$</span>)
                                            :
                                            (<span className="text-rose-600">{formatNumber(userPackage.today_profit, 2)}$</span>)
                                        }
                                    </span>
                                </div>
                                <div className="flex flex-col flex-1  bg-[#4c535f] rounded-md shadow-lg  py-1">
                                    <span className="text-sm font-bold text-center text-white">Win/Lose</span>
                                    <span className="text-sm text-center text-gray-400">{userPackage.win}/{userPackage.lose}</span>
                                </div>
                            </div>
                        </CardRound>
                    </div>
                    <div className="mt-2">
                        <CardRound >
                            <div className="card-title">
                                <h3 className="font-bold text-white uppercase ">
                                    Settings
                                </h3>
                            </div>
                            <div className="flex w-full gap-2 mt-2">
                                <div className="flex flex-col flex-1  bg-[#4c535f] rounded-md shadow-lg py-1">
                                    <span className="text-sm font-bold text-center text-white">Amount</span>
                                    <span className="text-sm text-center text-gray-400">
                                        {formatNumber(userPackage.amount, 2)}$
                                    </span>
                                </div>
                                <div className="flex flex-col flex-1  bg-[#4c535f] rounded-md shadow-lg  py-1">
                                    <span className="text-sm font-bold text-center text-white">Stoploss</span>
                                    <span className="text-sm text-center text-gray-400">
                                        {formatNumber(Number(userPackage.day_lose) * Number(userPackage.amount), 2)}$
                                    </span>
                                </div>
                                <div className="flex flex-col flex-1  bg-[#4c535f] rounded-md shadow-lg  py-1">
                                    <span className="text-sm font-bold text-center text-white">Stopwin</span>
                                    <span className="text-sm text-center text-gray-400">
                                        {formatNumber(Number(userPackage.day_win) * Number(userPackage.amount), 2)}$
                                    </span>
                                </div>
                            </div>
                        </CardRound>
                    </div>
                    <div className="mt-2">
                        <CardRound loading={isLoadUserPackageHistories}>
                            <div className="card-title">
                                <h3 className="font-bold text-white uppercase ">
                                    Bet Histories
                                </h3>
                            </div>
                            <div className="flex w-full gap-2 mt-2 ">
                                {userPackageHistories.length == 0
                                    ?
                                    (
                                        <div>No data avaiable</div>
                                    )
                                    : (
                                        <div className="flex flex-col w-full gap-2 bet-items">
                                            {userPackageHistories.map((historyItem) => (
                                                <div className="flex flex-col bg-[#4c535f] rounded-sm shadow-lg p-2 w-full">
                                                    <div className="flex flex-row justify-between">
                                                        <div className="flex flex-row items-center text-xs"><AiFillClockCircle size={14} className="mr-1"></AiFillClockCircle>{historyItem.created_at}</div>
                                                        {historyItem.amount_win > 0
                                                            ?
                                                            (<span className="float-right bg-teal-500 badge">Win</span>)
                                                            :
                                                            (<span className="float-right badge bg-rose-500">Lose</span>)
                                                        }
                                                    </div>
                                                    <div className="flex flex-row items-center text-base">
                                                        <AiFillAlert size={14} className="mr-1"></AiFillAlert>
                                                        {historyItem.buy_sell} | {formatNumber(historyItem.open, 3)} - {formatNumber(historyItem.close, 3)}
                                                    </div>
                                                    <div className="flex flex-row items-center text-base">
                                                        <AiFillDollarCircle size={14} className="mr-1"></AiFillDollarCircle>
                                                        Bet: {formatNumber(historyItem.amount_bet, 2)}$
                                                    </div>
                                                    <div className="flex flex-row items-center text-base">
                                                        <AiFillDollarCircle size={14} className="mr-1"></AiFillDollarCircle>
                                                        Profit:
                                                        {
                                                            historyItem.amount_win > 0
                                                                ?
                                                                (<span className="text-teal-500">+{formatNumber(historyItem.amount_win, 2)}$</span>)
                                                                :
                                                                (<span className="text-teal-500">-{formatNumber(historyItem.amount_lose, 2)}$</span>)
                                                        }

                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                            </div>
                        </CardRound>
                    </div>
                </div>
            </div>
            <Modal
                visible={open}
                onOk={() => onSaveAuto()}
                onSecond={() => onSaveAutoStart()}
                onCancel={onClose}
                title={`Package - ${formInputName}`}
                okText="Save"
                cancelText="Close"
                isEnableOk={isFormValidate}
                secondText="Save & Start"
                isEnableSecond={isFormValidate}
                disabledAll={isSaving}
            >
                <div className="relative w-full">
                    <div className="  max-h-[60vh] overflow-y-auto w-full">
                        <div className="flex flex-col gap-1 mb-2 form-item">
                            <label for="fundAmount" className="block font-medium text-md ">
                                Amount Factor<span className="ml-1 text-red-500">*</span>
                            </label>
                            <div className="flex items-center px-2 py-2 bg-white border border-gray-300 rounded-md form-input focus-within:ring-2 focus-within:ring-blue-500">
                                <span className="mr-2 text-sm font-medium text-gray-500">$</span>
                                <input
                                    type="number"
                                    placeholder="Enter amount"
                                    value={formInputFund}
                                    min={1}
                                    max={100}
                                    onChange={onChangeAmount}
                                    className="flex-1 text-gray-700 placeholder-gray-400 bg-transparent focus:outline-none"
                                />
                            </div>
                            <p className="text-sm text-orange-500">
                                The amount coefficient corresponds to the amount of money on 1 command(min: 1$, max: 99$)
                            </p>
                        </div>
                        <div className="flex flex-col gap-1 mb-2 form-item">
                            <label for="fundAmount" className="block font-medium text-md ">
                                Stop Win Factor
                            </label>
                            <div className="relative bg-white border border-gray-300 rounded-lg focus-within:ring-2 focus-within:ring-blue-500">
                                <select
                                    value={selectedStopWinOption}
                                    onChange={handleSelectStopWinChange}
                                    className="block w-full px-2 py-2 text-gray-700 bg-transparent appearance-none focus:outline-none"
                                >
                                    <option value="" disabled>
                                        -- Select stop win factor --
                                    </option>
                                    <option value="5">x5 | {formatNumber(formInputFund * 5, 2)}$</option>
                                    <option value="10">x10 | {formatNumber(formInputFund * 10, 2)}$</option>
                                    <option value="15">x15 | {formatNumber(formInputFund * 15, 2)}$</option>
                                    <option value="20">x20 | {formatNumber(formInputFund * 20, 2)}$</option>
                                    <option value="25">x25 | {formatNumber(formInputFund * 25, 2)}$</option>
                                    <option value="30">x30 | {formatNumber(formInputFund * 30, 2)}$</option>
                                </select>

                                {/* Arrow Icon */}
                                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-4 h-4 text-gray-500"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-1 mb-2 form-item">
                            <label for="fundAmount" className="block font-medium text-md ">
                                Stop Loss Factor
                            </label>
                            <div className="relative bg-white border border-gray-300 rounded-lg focus-within:ring-2 focus-within:ring-blue-500">
                                <select
                                    value={selectedStopLossOption}
                                    onChange={handleSelectStopLossChange}
                                    className="block w-full px-2 py-2 text-gray-700 bg-transparent appearance-none focus:outline-none"
                                >
                                    <option value="" disabled>
                                        -- Select stop loss factor --
                                    </option>
                                    <option value="5">x5 | {formatNumber(formInputFund * 5, 2)}$</option>
                                    <option value="10">x10 | {formatNumber(formInputFund * 10, 2)}$</option>
                                    <option value="15">x15 | {formatNumber(formInputFund * 15, 2)}$</option>
                                    <option value="20">x20 | {formatNumber(formInputFund * 20, 2)}$</option>
                                    <option value="25">x25 | {formatNumber(formInputFund * 25, 2)}$</option>
                                    <option value="30">x30 | {formatNumber(formInputFund * 30, 2)}$</option>
                                    <option value="40">x40 | {formatNumber(formInputFund * 40, 2)}$</option>
                                    <option value="50">x50 | {formatNumber(formInputFund * 50, 2)}$</option>
                                    <option value="75">x75 | {formatNumber(formInputFund * 75, 2)}$</option>
                                    <option value="100">x100 | {formatNumber(formInputFund * 100, 2)}$</option>
                                </select>

                                {/* Arrow Icon */}
                                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-4 h-4 text-gray-500"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-1 mb-2 form-item">
                            <label className="block font-medium text-md ">
                                Name<span className="ml-1 text-red-500">*</span>
                            </label>
                            <div className="flex items-center px-2 py-2 bg-white border border-gray-300 rounded-md form-input focus-within:ring-2 focus-within:ring-blue-500">
                                <input
                                    value={formInputName}
                                    onChange={onChangeName}
                                    type="text"
                                    placeholder="Enter name"
                                    className="flex-1 text-gray-700 placeholder-gray-400 bg-transparent focus:outline-none"
                                />
                            </div>
                        </div>
                        <div className="flex flex-col gap-1 mb-2 form-item">
                            <label className="block font-medium text-md ">
                                Note
                            </label>
                            <div className="flex items-center px-2 py-2 bg-white border border-gray-300 rounded-md form-input focus-within:ring-2 focus-within:ring-blue-500">
                                <textarea
                                    value={formInputNote}
                                    onChange={onchangeNote}
                                    placeholder="Enter note"
                                    className="flex-1 text-gray-700 placeholder-gray-400 bg-transparent resize-none focus:outline-none"
                                    rows="3"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    {isSaving ? (<div className="flex absolute items-center justify-center  bg-[#202938] size-full top-0 left-0 opacity-55">
                        <TailSpin color="#ffffff" height={50} width={50} />
                    </div>) : (
                        <></>
                    )}
                </div>
            </Modal>
        </Animate>
    );
}

export default MyPackageInfoPage;