import req from "../libs/req";

const startAuto = async (id) => {
  return req.post("/start", {
    id: id,
  });
};

const stopAuto = async (id) => {
  return req.post("/stop", {
    id: id
  });
};

const getHistoryDaily = async (page = 1, take = 10) => {
  return req.get("/history-daily", {
    params: {
      page,
      take,
    },
  });
};

const getHistoryBet = async (page = 1, take = 10) => {
  return req.get("/history-bet", {
    params: {
      page,
      take,
    },
  });
};

export { startAuto, stopAuto, getHistoryDaily, getHistoryBet };
